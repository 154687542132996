import React from 'react'
import moment from 'moment'; // Import the moment library

// helper.js
// Refactor and provide common functions to be used across DPT.

export const formatDateTime = (datetime) => {
    // Parse the MySQL date-time string using moment
    const parsedDateTime = moment(datetime, 'YYYYMMDDTHHmmss');
    // Format the date-time string as per your desired format (e.g., "YYYY-MM-DD HH:mm:ss")
    return parsedDateTime.format('YYYY-MM-DD HH:mm:ss');
  };

  export const formatDateTime2nd = (datetime) => {
    // Parse the MySQL date-time string using moment
    const parsedDateTime = moment(datetime, 'YYYY-MM-DDTHH:mm:ss');
    // Format the date-time string as per your desired format (e.g., "YYYY-MM-DD HH:mm:ss")
    return parsedDateTime.format('YYYY-MM-DD HH:mm:ss');
  };