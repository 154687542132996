import React, { useState } from 'react';
import { Tabs } from 'antd';
import S3Explorer from './S3Explorer';
import ArtifactoryExplorer from './ArtifactoryExplorer';

const { TabPane } = Tabs;

const Explorer = () => {
  const [activeTab, setActiveTab] = useState('1'); // Default active tab

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case '1':
        return <S3Explorer />;
      case '2':
        return <ArtifactoryExplorer />;
      default:
        return null;
    }
  };

  return (
    <div style={{"margin-left": 40}}>
      <Tabs activeKey={activeTab} onChange={handleTabChange}>
        <TabPane tab="S3 Contents" key="1">
        </TabPane>
        <TabPane tab="Artifactory Contents" key="2">
        </TabPane>
      </Tabs>
      <div className="tab-content">{renderTabContent()}</div>
    </div>
  );
};

export default Explorer