/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBuild = /* GraphQL */ `
  query GetBuild($buildID: ID!) {
    getBuild(buildID: $buildID) {
      buildID
      componentId
      componentName
      version
      buildUrl
      buildConfig
      buildProjectID
      buildTimestamp
      appEnvironmentNames
    }
  }
`;
export const getDeployableComponent = /* GraphQL */ `
  query GetDeployableComponent($componentId: ID!) {
    getDeployableComponent(componentId: $componentId) {
      componentId
      componentName
    }
  }
`;
export const listBuilds = /* GraphQL */ `
  query ListBuilds(
    $filter: TableBuildFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBuilds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        buildID
        componentName
        version
        buildUrl
        active
        appEnvironmentName
        buildTimestamp
      }
      nextToken
    }
  }
`;
export const listBuildsDeploy = /* GraphQL */ `
  query ListBuildsDeploy($filter: TableBuildFilterInput, $nextToken: String) {
    listBuildsDeploy(filter: $filter, nextToken: $nextToken) {
      items {
        buildID
        componentName
        version
        buildUrl
        active
        appEnvironmentName
        buildTimestamp
      }
      nextToken
    }
  }
`;
export const listBuildsDeploybyBuildID = /* GraphQL */ `
  query ListBuildsDeploybyBuildID(
    $filter: TableBuildFilterInput
    $nextToken: String
  ) {
    listBuildsDeploybyBuildID(filter: $filter, nextToken: $nextToken) {
      items {
        buildID
        componentName
        version
        buildUrl
        active
        appEnvironmentName
        buildTimestamp
      }
      nextToken
    }
  }
`;
export const listDeployableComponents = /* GraphQL */ `
  query ListDeployableComponents(
    $filter: TableDeployableComponentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeployableComponents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        componentId
        componentName
      }
      nextToken
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: CustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        customerID
        customerName
        awsRegion {
          awsRegionId
          regionName
          notes
        }
        iconLocation
      }
      nextToken
    }
  }
`;
export const listApp_Environments = /* GraphQL */ `
  query ListApp_Environments {
    listApp_Environments {
      items {
        appEnvironmentID
        appEnvironmentName
        customerName
        environmentStatus
        ssProjectURL
        appEnvironmentURL
        usecase
      }
      nextToken
    }
  }
`;
export const listAllDeploymentExceptions = /* GraphQL */ `
  query ListAllDeploymentExceptions {
    listAllDeploymentExceptions {
      items {
        deploymentExceptionID
        buildID
        appEnvironmentName
        componentName
        executionId
        taskName
        notes
        dateTimeStamp
      }
      nextToken
    }
  }
`;
export const listCustomerEnvironments = /* GraphQL */ `
  query ListCustomerEnvironments {
    listCustomerEnvironments {
      items {
        customerName
        iconLocation
        regionName
        Total
        Environments
      }
      nextToken
    }
  }
`;
export const getAppEnvironmentDetailsByName = /* GraphQL */ `
  query GetAppEnvironmentDetailsByName($appEnvironmentName: String!) {
    getAppEnvironmentDetailsByName(appEnvironmentName: $appEnvironmentName) {
      appEnvironmentID
      appEnvironmentName
      customerName
      environmentStatus
      ssProjectURL
      appEnvironmentURL
      usecase
    }
  }
`;
export const listDeployments = /* GraphQL */ `
  query ListDeployments(
    $filter: TableDeploymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeployments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        componentName
        version
        buildTimestamp
        buildConfig
        appEnvironmentName
      }
      nextToken
    }
  }
`;
export const listDeploymentsWithBuildId = /* GraphQL */ `
  query ListDeploymentsWithBuildId(
    $filter: TableDeploymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeploymentsWithBuildId(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        buildID
        componentName
        version
        buildUrl
        buildTimestamp
        dateTimeStamp
      }
      nextToken
    }
  }
`;
export const getMetricCountAppEnvironment = /* GraphQL */ `
  query GetMetricCountAppEnvironment {
    getMetricCountAppEnvironment
  }
`;
export const getMetricCountCustomers = /* GraphQL */ `
  query GetMetricCountCustomers {
    getMetricCountCustomers
  }
`;
export const getMetricCountBuild = /* GraphQL */ `
  query GetMetricCountBuild {
    getMetricCountBuild
  }
`;
export const getSsExecutions = /* GraphQL */ `
  query GetSsExecutions($appEnvironmentName: String!) {
    getSsExecutions(appEnvironmentName: $appEnvironmentName) {
      items {
        executionId
        statusName
        platformName
        taskName
        parameters
        triggerDescriptor
        dateTimeStamp
        updateDateTimeStamp
      }
      nextToken
    }
  }
`;
export const getSsExecutionList = /* GraphQL */ `
  query GetSsExecutionList {
    getSsExecutionList {
      items {
        environmentName
        statusName
        platformName
        taskName
        parameters
        dateTimeStamp
      }
      nextToken
    }
  }
`;
export const getS3ObjectList = /* GraphQL */ `
  query GetS3ObjectList {
    getS3ObjectList {
      key
    }
  }
`;
export const getArtifactoryObjectList = /* GraphQL */ `
  query GetArtifactoryObjectList($path: String!) {
    getArtifactoryObjectList(path: $path) {
      name
      isFile
      path
    }
  }
`;
export const getAppEnvironmentsByAWSRegion = /* GraphQL */ `
  query GetAppEnvironmentsByAWSRegion {
    getAppEnvironmentsByAWSRegion {
      items {
        awsRegion
        total
      }
      nextToken
    }
  }
`;
export const getDeploymentsByDate30 = /* GraphQL */ `
  query GetDeploymentsByDate30 {
    getDeploymentsByDate30 {
      items {
        day
        total_deployments
      }
      nextToken
    }
  }
`;
export const getBuildsByDate30 = /* GraphQL */ `
  query GetBuildsByDate30 {
    getBuildsByDate30 {
      items {
        day
        total_builds
      }
      nextToken
    }
  }
`;
export const getBranches = /* GraphQL */ `
  query GetBranches($repositoryName: String!) {
    getBranches(repositoryName: $repositoryName)
  }
`;
export const getMetricCountSS = /* GraphQL */ `
  query GetMetricCountSS {
    getMetricCountSS
  }
`;
export const getMetricCountSS31Days = /* GraphQL */ `
  query GetMetricCountSS31Days {
    getMetricCountSS31Days
  }
`;
export const getMetricCountDeployedComponentsWithEnvironmentId = /* GraphQL */ `
  query GetMetricCountDeployedComponentsWithEnvironmentId(
    $appEnvironmentID: ID!
  ) {
    getMetricCountDeployedComponentsWithEnvironmentId(
      appEnvironmentID: $appEnvironmentID
    )
  }
`;
export const getMetricCountActiveDeployment = /* GraphQL */ `
  query GetMetricCountActiveDeployment {
    getMetricCountActiveDeployment
  }
`;
export const listServerlessApps = /* GraphQL */ `
  query ListServerlessApps {
    listServerlessApps {
      items {
        appname
        trunkBranch
        bitbucketRepo
        devAccountId
        appType
      }
      nextToken
    }
  }
`;
export const getServerlessApp = /* GraphQL */ `
  query GetServerlessApp($appname: String!) {
    getServerlessApp(appname: $appname) {
      appname
      trunkBranch
      bitbucketRepo
      devAccountId
      appType
    }
  }
`;
export const getAppset = /* GraphQL */ `
  query GetAppset($appsetId: ID!) {
    getAppset(appsetId: $appsetId) {
      appsetId
      owner
      serverlessBuilds {
        serverless_app {
          appname
          trunkBranch
          bitbucketRepo
          devAccountId
          appType
        }
        build_id
        commit_id
      }
    }
  }
`;
export const getServerlessBuild = /* GraphQL */ `
  query GetServerlessBuild(
    $build_id: ID
    $commit_id: String
    $appname: String
  ) {
    getServerlessBuild(
      build_id: $build_id
      commit_id: $commit_id
      appname: $appname
    ) {
      serverless_app {
        appname
        trunkBranch
        bitbucketRepo
        devAccountId
        appType
      }
      build_id
      commit_id
    }
  }
`;
export const getServerlessBuildDependencies = /* GraphQL */ `
  query GetServerlessBuildDependencies($appname: String!, $commit_id: String!) {
    getServerlessBuildDependencies(appname: $appname, commit_id: $commit_id)
  }
`;
export const getUDPConfig = /* GraphQL */ `
  query GetUDPConfig($appname: String!, $commit_id: String!) {
    getUDPConfig(appname: $appname, commit_id: $commit_id)
  }
`;
export const getRecursiveDependenciesForServerlessBuild = /* GraphQL */ `
  query GetRecursiveDependenciesForServerlessBuild(
    $appname: String!
    $commit_id: String!
  ) {
    getRecursiveDependenciesForServerlessBuild(
      appname: $appname
      commit_id: $commit_id
    ) {
      serverless_app {
        appname
        trunkBranch
        bitbucketRepo
        devAccountId
        appType
      }
      build_id
      commit_id
    }
  }
`;
export const describeAppsetsInPipeline = /* GraphQL */ `
  query DescribeAppsetsInPipeline(
    $pipelineInstanceName: String!
    $appsetIds: [ID]!
    $include_appsets_in_terminal_states: Boolean!
    $exclude_redundant_appsets: Boolean
  ) {
    describeAppsetsInPipeline(
      pipelineInstanceName: $pipelineInstanceName
      appsetIds: $appsetIds
      include_appsets_in_terminal_states: $include_appsets_in_terminal_states
      exclude_redundant_appsets: $exclude_redundant_appsets
    ) {
      pipelineInstanceName
      appset {
        appsetId
        owner
        serverlessBuilds {
          serverless_app {
            appname
            trunkBranch
            bitbucketRepo
            devAccountId
            appType
          }
          build_id
          commit_id
        }
      }
      state
      status
      status_category
    }
  }
`;
export const describeCicdStateInstance = /* GraphQL */ `
  query DescribeCicdStateInstance(
    $pipelineInstanceName: String!
    $state_name: String!
  ) {
    describeCicdStateInstance(
      pipelineInstanceName: $pipelineInstanceName
      state_name: $state_name
    ) {
      state_name
      env_name
      aws_account_id
      aws_region
      pipelineInstanceName
    }
  }
`;
export const listCicdPipelineInstances = /* GraphQL */ `
  query ListCicdPipelineInstances($limit: Int, $nextToken: String) {
    listCicdPipelineInstances(limit: $limit, nextToken: $nextToken)
  }
`;
export const getCicdTrailMap = /* GraphQL */ `
  query GetCicdTrailMap($pipelineInstanceName: String!, $appsetId: ID!) {
    getCicdTrailMap(
      pipelineInstanceName: $pipelineInstanceName
      appsetId: $appsetId
    ) {
      pipelineInstanceName
      appset {
        appsetId
        owner
        serverlessBuilds {
          serverless_app {
            appname
            trunkBranch
            bitbucketRepo
            devAccountId
            appType
          }
          build_id
          commit_id
        }
      }
      state
      status
      status_category
    }
  }
`;
export const getReleaseAppset = /* GraphQL */ `
  query GetReleaseAppset($pipelineInstanceName: String!) {
    getReleaseAppset(pipelineInstanceName: $pipelineInstanceName) {
      appsetId
      owner
      serverlessBuilds {
        serverless_app {
          appname
          trunkBranch
          bitbucketRepo
          devAccountId
          appType
        }
        build_id
        commit_id
      }
    }
  }
`;
export const getTeamCityServerVersion = /* GraphQL */ `
  query GetTeamCityServerVersion {
    getTeamCityServerVersion
  }
`;
export const getTeamCityBuildChanges = /* GraphQL */ `
  query GetTeamCityBuildChanges($buildID: ID!) {
    getTeamCityBuildChanges(buildID: $buildID) {
      id
      version
      username
      date
      href
      webUrl
      git_comment
    }
  }
`;
export const checkIfPackageInArtifactory = /* GraphQL */ `
  query CheckIfPackageInArtifactory(
    $componentName: String!
    $version: String!
  ) {
    checkIfPackageInArtifactory(
      componentName: $componentName
      version: $version
    )
  }
`;
export const getAppsetSpec = /* GraphQL */ `
  query GetAppsetSpec($name: String!, $recurse: Boolean!) {
    getAppsetSpec(name: $name, recurse: $recurse) {
      name
      owner
      serverlessBuildCommits {
        appname
        commit_id
      }
      child_appset_spec_names
    }
  }
`;
export const listAppsetSpecs = /* GraphQL */ `
  query ListAppsetSpecs($owner: String) {
    listAppsetSpecs(owner: $owner) {
      name
      owner
      dateTimeStampCreated
    }
  }
`;
