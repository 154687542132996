import React, { useEffect, useState}  from 'react'
import {Col, Row, Statistic} from "antd"
import { Select, Table } from 'antd'
import PeopleIcon from '@mui/icons-material/People'
import TimerIcon from '@mui/icons-material/Timer'
import EngineeringIcon from '@mui/icons-material/Engineering'
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch'
import BuildIcon from '@mui/icons-material/Build'
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import RunCircleIcon from '@mui/icons-material/RunCircle';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import { getMetricCountAppEnvironment, getMetricCountActiveDeployment, getMetricCountCustomers, getMetricCountBuild, getAppEnvironmentsByAWSRegion, getMetricCountSS, getMetricCountSS31Days, getDeploymentsByDate30, getBuildsByDate30, getTeamCityServerVersion } from "../graphql/queries"
import { API, graphqlOperation } from "aws-amplify";
import { Pie, Bar } from '@ant-design/plots';




function Metrics() {
  const [countSS, setCountSS] = useState([])
  const [countSS31Days, setCountSS31Days] = useState([])
  const [countAppEnvironment, setCountAppEnvironment] = useState([])
  const [countCustomers, setCountCustomers] = useState([])
  const [countBuild, setCountBuild] = useState([])
  const [countAppEnvironmentByAWSRegion, setCountAppEnvironmentByAWSRegion] = useState([])
  const [countDeploymentsByDate30, setDeploymentsByDate30]  = useState([])
  const [countBuildsByDate30, setBuildsByDate30] = useState([])
  const [countActiveDeployment, setCountActiveEnvironment] = useState([])
  const [TeamCityVersion, setTeamCityServerVersion] = useState([])
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [chartData, setChartData] = useState([]);
 
  const countAppEnvironmentByAWSRegionTest = [
    { region: 'Region 1', count: 10 },
    { region: 'Region 2', count: 5 },
    { region: 'Region 3', count: 8 },
    // ...
  ];


  const BarChart = ({ data }) => {

    console.log('Data Array:', data);
    const colors = ['#1890FF', '#13C2C2', '#2FC25B', '#FACC14', '#F04864', '#FF6600', '#52C41A', '#108EE9'];

    const config = {
      data,
      xField: 'total_deployments',
      yField: 'day',
      color: (item) => {
        const index = data.findIndex((entry) => entry.day === item.day);
        return colors[index % colors.length];
      },
      label: {
        position: 'middle',
        style: {
          fill: '#FFFFFF',
          opacity: 1.0,
        },

      },
      width: 150, // Adjust the width of the chart
      height: 175, // Adjust the height of the chart
    };
  
    return <Bar {...config} />;
  };

  const BarChart2 = ({ data }) => {

    console.log('Data Array:', data);
    const colors = ['#1890FF', '#13C2C2', '#2FC25B', '#FACC14', '#F04864', '#FF6600', '#52C41A', '#108EE9'];

    const config = {
      data,
      xField: 'total_builds',
      yField: 'day',
      color: (item) => {
        const index = data.findIndex((entry) => entry.day === item.day);
        return colors[index % colors.length];
      },
      label: {
        position: 'middle',
        style: {
          fill: '#FFFFFF',
          opacity: 0.6,
        },
      },
      width: 150, // Adjust the width of the chart
      height: 175, // Adjust the height of the chart
    };
  
    return <Bar {...config} />;
  };

  

  const PieChart = ({ dataSource }) => {
    const transformedData = dataSource.map((item) => ({
      type: item.awsRegion,
      value: item.total,
    }));
  
    const config = {
      data: transformedData,
      angleField: 'value',
      colorField: 'type',
      width: '50%',
      height: 220,
      legend: {
        style: {
          fill: '#00FF00', // Change to the desired color
        },
      },
    };
  
    return <Pie {...config} />;
  };
  
  
  
  const columns = [
    {
      title: 'AWS Region',
      dataIndex: 'awsRegion',
      key: 'awsRegion',
      sorter: (a,b) => a.awsRegion - b.awsRegion
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      sorter: (a,b) => a.total - b.total
    }
  ];

  useEffect(() => {
    fetchCountAppEnvironment()
    fetchCountCustomers()
    fetchCountBuild()
    fetchCountAppEnvironmentByAWSRegion()
    fetchDeploymentsByDate30()
    fetchBuildsByDate30()
    fetchCountSS()
    fetchCountSS31Days()
    fetchCountActiveDeployment()
    fetchTeamCityServerVersion()
  }, [])

  async function fetchCountAppEnvironment() {
    const api_ret = await API.graphql(graphqlOperation(getMetricCountAppEnvironment))
    setCountAppEnvironment(api_ret.data.getMetricCountAppEnvironment)
  }

  async function fetchCountActiveDeployment() {
    const api_ret = await API.graphql(graphqlOperation(getMetricCountActiveDeployment))
    setCountActiveEnvironment(api_ret.data.getMetricCountActiveDeployment)
  }


  async function fetchCountAppEnvironmentByAWSRegion() {
    const api_ret = await API.graphql(graphqlOperation(getAppEnvironmentsByAWSRegion))
    setCountAppEnvironmentByAWSRegion(api_ret.data.getAppEnvironmentsByAWSRegion.items)
  }

  async function fetchDeploymentsByDate30() {
    const api_ret = await API.graphql(graphqlOperation(getDeploymentsByDate30))
    setDeploymentsByDate30(api_ret.data.getDeploymentsByDate30.items)
    setLoading(false);
  }

  

  async function fetchBuildsByDate30() {
    const api_ret = await API.graphql(graphqlOperation(getBuildsByDate30))
    setBuildsByDate30(api_ret.data.getBuildsByDate30.items)
    setLoading2(false);
  }

  async function fetchCountCustomers() {
    const api_ret = await API.graphql(graphqlOperation(getMetricCountCustomers))
    setCountCustomers(api_ret.data.getMetricCountCustomers)
  }

  async function fetchCountBuild() {
    const api_ret = await API.graphql(graphqlOperation(getMetricCountBuild))
    setCountBuild(api_ret.data.getMetricCountBuild)
  }

  async function fetchCountSS31Days() {
    const api_ret = await API.graphql(graphqlOperation(getMetricCountSS31Days))
    setCountSS31Days(api_ret.data.getMetricCountSS31Days)
  }

  async function fetchCountSS() {
    const api_ret = await API.graphql(graphqlOperation(getMetricCountSS))
    setCountSS(api_ret.data.getMetricCountSS)
  }

  
  async function fetchTeamCityServerVersion() {
    const api_ret = await API.graphql(graphqlOperation(getTeamCityServerVersion))
    setTeamCityServerVersion(api_ret.data.getTeamCityServerVersion)
  }

  const titleStyle = {
    color: 'white', 
  };
  console.log(countAppEnvironmentByAWSRegion);

  const updatedData = countAppEnvironmentByAWSRegion.map((item) => ({
    awsRegion: item.awsRegion,
    total: parseInt(item.total, 10),
  }));

  const updatedData2 = countDeploymentsByDate30.map((item) => ({
    day: item.day,
    total_deployments: parseInt(item.total_deployments, 10),
  }));

  const updatedData3 = countBuildsByDate30.map((item) => ({
    day: item.day,
    total_builds: parseInt(item.total_builds, 10),
  }));

  const ServerfulStats = () => {
    return (
      <Row style={{"margin-left": 120, "margin-top": 10, alignItems: 'center'}} gutter={5}>
      <Col span={14}>
      <h4 style={{"color": 'white', "margin-top": 2, "margin-bottom": 10}}>Serverful Deployments By Date (last 30 days)</h4>
      {loading ? (
            <p style={{ color: 'yellow' }} className="blink">Loading...</p> // Display a loading message or spinner
          ) : (
            <BarChart data={updatedData2} />
          )}
      
      </Col>

      <Col span={14}>
      <h4 style={{"color": 'white', "margin-top": 2, "margin-bottom": 10}}>Builds By Date (last 30 days)</h4>
      {loading2 ? (
            <p style={{ color: 'yellow' }} className="blink">Loading...</p> // Display a loading message or spinner
          ) : (
            <BarChart2 data={updatedData3} />
          )}
      
      </Col>
    </Row>
    )
  };

  const GeneralStats = () => {
    return (
      <Row style={{"margin-left": 120, "margin-top": 30}} gutter={20}>
        <Col span={8}>
        <Statistic title={<span style={titleStyle}>Clients</span>} value={countCustomers} prefix={<PeopleIcon/>}/>
        </Col>
        <Col span={8}>
        <Statistic title={<span style={titleStyle}>Builds</span>} value={countBuild} prefix={<BuildIcon/>}/>
        </Col>
        <Col span={8}>
        <Statistic title={<span style={titleStyle}>Deployment Time (Hours)</span>} value={1.5} precision={1} prefix={<TimerIcon/>}/>
        </Col>
        </Row>
    )
  }

  const SelfServiceStats = () => {
    return (
      <Row style={{"margin-left": 120, "margin-top": 25}} gutter={20}>       
        <Col span={8}>
        <Statistic title={<span style={titleStyle}>Environments</span>} value={countAppEnvironment} prefix={<RocketLaunchIcon/>} />
        </Col>
        <Col span={8}>
        <Statistic title={<span style={titleStyle}>Self Service Uses last 31 days</span>} value={countSS31Days} prefix={<EngineeringIcon/>} />
        </Col>
        <Col span={8}>
        <Statistic title={<span style={titleStyle}>Self Service Total</span>} value={countSS} prefix={<SettingsSuggestIcon/>} />
        </Col>
        </Row>
    )
  }

  const UDPStats = () => {
    return (
      <Row style={{"margin-left": 120, "margin-top": 25}} gutter={20}>
        <Col span={8}>
        <Statistic title={<span style={titleStyle}>UDP Users</span>} value={80} prefix={<InsertEmoticonIcon/>} />
        </Col>
        <Col span={8}>
        <Statistic title={<span style={titleStyle}>Active Deployments</span>} value={countActiveDeployment} prefix={<RunCircleIcon/>} />
        </Col>
        <Col span={8}>
        <Statistic title={<span style={titleStyle}>TeamCity Version</span>} valueStyle={{ fontSize: '18px' }} value={TeamCityVersion} prefix={<BuildCircleIcon/>} />
        </Col>
    </Row>
    )
  }

  const RegionStats = () => {
    return (
      <Row style={{"margin-left": 60, "margin-top": 30}} gutter={3}>
      <Col span={9}>
   <h5 style={{"color": 'white', "margin-top": 5}} > AWS Regions and Environments </h5>    
       <Table style={{"margin-top": 15, "marginRight" : 5, width: '55%' }} dataSource={countAppEnvironmentByAWSRegion} columns={columns} pagination={countAppEnvironmentByAWSRegion.length > 10 ? true: false}/>
     </Col>
     <Col span={7}>
       <h5 style={{"color": 'white', "margin-top": 5 }}>AWS Regions and Environments</h5>
       <div style={{"margin-top": 65}}>
       <PieChart dataSource={updatedData} />
       </div>
       </Col>
       </Row>
    )
  }

  return (
    <div style={{"margin-top": 20, "display": "flex", "flex-direction": "row", justifyContent: 'space-between'}}>
    <div>
    <ServerfulStats/>
    <GeneralStats/>
    <SelfServiceStats/>
    <UDPStats/>
    <RegionStats/>
    </div>
    </div>

  )
}

export default Metrics