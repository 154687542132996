import React from 'react'

function Home() {
  return (
    <div style={{
      color: "white", 
      fontWeight: "bold",
      fontSize: 30,
      textAlign: "center",
      padding: 70,
      width: "70%",
      height: 300,
    }}>DP TRAKKER is a CI/CD and deployment dashboard by the Delivery Pipeline team. It provides information on the available builds and SaaS environments. Unified Delivery Pipeline (UDP) framework provisions cloud based devops strategy. 
    </div>
  )
}

export default Home