import React, { useEffect, useState } from "react";
import {AutoComplete, Select, Table, Card, Col, Row, Statistic, Input, Popover, Button, Modal, Tooltip} from "antd";
import { API, graphqlOperation } from "aws-amplify";
import { Link } from "react-router-dom";

import { listDeployableComponents } from "../graphql/queries";
import { listBuildsDeploy } from "../graphql/queries";
import { listBuildsDeploybyBuildID } from "../graphql/queries";

import { formatDateTime } from "./helper"; // Adjust the path based on the actual file location
import {getMetricCountDeployedComponentsWithEnvironmentId, getTeamCityBuildChanges} from "../graphql/queries";

const { Option } = Select;
const searchInputStyle = {"margin-top": 20, background: "black", color: "white", width: "300px"};

function Builds() {
  const [deployableComponents, setDeployableComponents] = useState([]);
  const [builds, setBuilds] = useState([]);
  const [buildid, setBuildid] = useState(null);
  const [deployments, setDeployments] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [apiRetDataItemsLength, setApiRetDataItemsLength] = useState(null);
  const [environmentsHeader, setEnvironmentsHeader] = useState("Environments: Deployed");
  const [showAllEnvironments, setShowAllEnvironments] = useState(false);
  const [selectedBuildID, setSelectedBuildID] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [changes, setChanges] = useState([]);
  const [error, setError] = useState(null);
  const [empty, setEmpty] = useState(null);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [isModalClosed, setIsModalClosed] = useState(false);
  const [pinnedRows, setPinnedRows] = useState([]);
  const [setPinnedRowsByComponent] = useState({});
  const [fetchPinnedChanges, setFetchPinnedChanges] = useState([]);

  const toggleEnvironmentsHeader = () => {
    if (environmentsHeader === "Environments: Deployed") {
      setEnvironmentsHeader("Environments: Not Deployed");
    } else {
      setEnvironmentsHeader("Environments: Deployed");
    }
  };
  const filteredBuilds = showAllEnvironments
    ? builds
    : builds.filter((record) =>
        environmentsHeader === "Environments: Deployed"
          ? record.appEnvironmentName !== ""
          : record.appEnvironmentName === ""
      );
  const handleClick = (buildID) => {
    setSelectedBuildID(buildID);
    setModalVisible(true);
  };

  const pinRow = async (row) => {
    if (!pinnedRows.some(pinnedRow => pinnedRow.key === row.key)) {
      setPinnedRows(prevPinnedRows => [...prevPinnedRows, row]);
  
      // Fetch changes based on the pinned build
      try {
        const response = await API.graphql(
          graphqlOperation(getTeamCityBuildChanges, {
            buildID: row.buildID,
          })
        );
        const changesData = response.data.getTeamCityBuildChanges;
        const changesWithComponentName = {
          buildID: row.buildID,
          componentName: row.componentName,
          changes: changesData,
          buildUrl: row.buildUrl,
        };
        // Include buildID along with changes data
        const changesWithBuildID = {
          buildID: row.buildID,
          changes: changesData,
          buildUrl: row.buildUrl, // Add the buildUrl to the changesWithBuildID object
        };
        setFetchPinnedChanges(prevFetchPinnedChanges => [...prevFetchPinnedChanges, changesWithComponentName]);
        setFetchPinnedChanges(prevFetchPinnedChanges => [...prevFetchPinnedChanges, changesWithBuildID]);
        console.log("Fetched changes for pinned build:", changesWithBuildID);
      } catch (error) {
        console.error("Error fetching changes for pinned build:", error);
        // Handle error
      }
    }
  };
  
  const unpinRow = (row) => {
    setPinnedRows((prevPinnedRows) => {
      const updatedPinnedRows = prevPinnedRows.filter(
        (pinnedRow) => pinnedRow.key !== row.key
      );
  
      // Remove the corresponding data from fetchPinnedChanges
      const updatedFetchPinnedChanges = fetchPinnedChanges.filter(
        (changesData) => changesData.buildID !== row.buildID
      );
  
      setFetchPinnedChanges(updatedFetchPinnedChanges);
      return updatedPinnedRows;
    });
  };
  
  
  
  const [showAllChangesModal, setShowAllChangesModal] = useState(false);
  const openAllChangesModal = () => {
    setShowAllChangesModal(true);
  };
  
  const closeAllChangesModal = () => {
    setShowAllChangesModal(false);
  };
  
  useEffect(() => {
    fetchDeployableComponents();
  }, []);

  useEffect(() => {
    console.log("fetchPinnedChanges:", fetchPinnedChanges);
  }, [fetchPinnedChanges]);
  
  useEffect(() => {
    // Fetching changes based on selectedBuildID
    if (selectedBuildID) {
      const fetchChanges = async () => {
        try {
          setChanges([]);
          setEmpty(null);
          const response = await API.graphql(
            graphqlOperation(getTeamCityBuildChanges, {
              buildID: selectedBuildID,
            })
          );
          const changesData = response.data.getTeamCityBuildChanges; // Extracting the array of changes
          setChanges(changesData);
          setError(null); // Clearing any previous errors
          console.log(
            "The changesdata after fetchChanges is ",
            changesData.length
          );
          if (changesData.length == 0) {
            setEmpty("No change comments provided");
          }
        } catch (error) {
          console.error("Error fetching changes:", error);
          setError(
            "Older or older SNAPSHOT build (no longer active on build system). Build details not available check with SCM team."
          ); // Set the error message
        }
      };

      fetchChanges();
    }
  }, [selectedBuildID]);

  useEffect(() => {
    if (!modalVisible) {
      // Reset changes and error when the modal is closed
      setChanges([]);
      setError(null);
      setIsInitialRender(true);
    }
  }, [modalVisible]);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
    }
  }, [isInitialRender]);

  async function fetchDeployableComponents() {
    const api_ret = await API.graphql(
      graphqlOperation(listDeployableComponents)
    );
    setDeployableComponents(api_ret.data.listDeployableComponents.items);
  }

  async function setBuildsState(value) {
    const query_filter = { componentId: { eq: value } };
    console.log("the componentId is:", query_filter);
    try {
      // const api_ret = await API.graphql(graphqlOperation(listBuilds, {"filter": query_filter}))
      const api_ret = await API.graphql(
        graphqlOperation(listBuildsDeploy, { filter: query_filter })
      );
      console.log("the graphql operation triggered:", api_ret.data);
      // setBuilds(api_ret.data.listBuilds.items)
      setBuilds(api_ret.data.listBuildsDeploy.items);
      console.log("the build is:", builds);
    } catch (error) {
      console.log(error);
    }
  }

  function getData(val) {
    setBuildid(val.target.value);
    setShowTable(false); //needed to make the last lookup result disapper before new one is requested
    setApiRetDataItemsLength(null);
  }

  async function handleButtonCliick() {
    const query_filter = { buildID: { eq: buildid } };
    if (buildid != null) {
      const api_ret = await API.graphql(
        graphqlOperation(listBuildsDeploybyBuildID, { filter: query_filter })
      );
      setBuilds(api_ret.data.listBuildsDeploybyBuildID.items);
      const api_ret2 = await API.graphql(
        graphqlOperation(listBuildsDeploybyBuildID, { filter: query_filter })
      );
      setDeployments(api_ret2.data.listBuildsDeploybyBuildID.items);
      const deploymentsLength =
        api_ret2.data.listBuildsDeploybyBuildID.items.length;
      setApiRetDataItemsLength(deploymentsLength);
      if (deploymentsLength > 1) {
        console.log("deploymentsLength:", deploymentsLength);
        setShowTable(true);
        setEnvironmentsHeader("Environments: Deployed"); // Set the header to "Environments: Deployed" if there are deployments
      } else {
        setShowTable(false);
        setEnvironmentsHeader("Environments: Not Deployed"); // Set the header to "Environments: Not Deployed" if there are no deployments
      }
      
    }
  }


  const handleModalCancel = () => {
    setModalVisible(false);
    setChanges([]); // Reset changes to empty
    setError(null);
    setIsModalClosed(true);
  };

  useEffect(() => {
    if (isModalClosed) {
      setIsModalClosed(false);
    }
  }, [isModalClosed]);

  const modalContentStyle = {
    maxHeight: "400px", // Set the maximum height for the pop up modal for changes
    overflowY: "auto", // Enable vertical scrollbar if content exceeds maxHeight
  };

  const columns = [
    {
      title: "Build ID",
      dataIndex: "buildID",
      key: "buildID",
      render: (text, record) => (
        <span style={{ fontSize: "12px" }}>
          <Tooltip title={`Click to open link for build ${text}`}>
            <a href={record.buildUrl} target="_blank" rel="noopener noreferrer">
              {text}
            </a>
          </Tooltip>
        </span>
      ),
      sorter: (a, b) => a.buildID.localeCompare(b.buildID),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        return (
          <Input
            autoFocus
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      onFilter: (value, record) => {
        return record.buildID.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: "Component Name",
      dataIndex: "componentName",
      key: "componentName",
      sorter: (a, b) => a.componentName.localeCompare(b.componentName),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        return (
          <Input
            autoFocus
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      onFilter: (value, record) => {
        return record.componentName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
      render: (text) => <span style={{ fontSize: "12px" }}>{text}</span>,
      sorter: (a, b) => a.version.localeCompare(b.version),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        return (
          <Input
            autoFocus
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      onFilter: (value, record) => {
        return record.version.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: "Build Timestamp",
      dataIndex: "buildTimestamp",
      key: "buildTimestamp",
      render: (text, record) => (
        <span style={{ fontSize: "12px" }}>
          {formatDateTime(record.buildTimestamp)}
        </span>
      ), // Use the formatDateTime function to format the date-time
    },
  ];
  const columnsEnvironmentsToggle = [
    {
      title: (
        <span style={{ cursor: "pointer" }} onClick={toggleEnvironmentsHeader}>
          <Tooltip title="Click to toggle results">
            Environments:{" "}
            <span
              style={{
                color:
                  environmentsHeader === "Environments: Deployed"
                    ? "green"
                    : "inherit",
              }}
            >
              {environmentsHeader === "Environments: Deployed"
                ? "Deployed"
                : "Not Deployed"}
            </span>
          </Tooltip>
        </span>
      ),
      dataIndex: "appEnvironmentName",
      key: "appEnvironmentName",
      width: 350,
      render: (text, record) => {
        const environmentNames = text.split(", ");

        if (
          showAllEnvironments || environmentNames.length > 0 || text
            ? text
            : "No Environments"
        ) {
          return (
            <span style={{ fontSize: "12px" }}>
              {environmentNames.map((environmentName, index) => (
                <React.Fragment key={index}>
                  <Link
                    to={`/environments/${encodeURIComponent(environmentName)}`}
                  >
                    {environmentName}
                  </Link>
                  {index < environmentNames.length - 1 && ", "}
                </React.Fragment>
              ))}
            </span>
          );
        } else {
          return <span style={{ fontSize: "12px" }}>No Environments</span>;
        }
      },
    },
  ];

  const columnsEnvironmentsBase = [
    {
      title: "Environments",
      dataIndex: "appEnvironmentName",
      key: "appEnvironmentName",
      width: 350,
      render: (text, record) => {
        const environmentNames = text.split(", ");

        if (
          showAllEnvironments || environmentNames.length > 0 || text
            ? text
            : "No Environments"
        ) {
          return (
            <span style={{ fontSize: "12px" }}>
              {environmentNames.map((environmentName, index) => (
                <React.Fragment key={index}>
                  <Link
                    to={`/environments/${encodeURIComponent(environmentName)}`}
                  >
                    {environmentName}
                  </Link>
                  {index < environmentNames.length - 1 && ", "}
                </React.Fragment>
              ))}
            </span>
          );
        } else {
          return <span style={{ fontSize: "12px" }}>No Environments</span>;
        }
      },
    },
  ];

  const columnsChanges = [
    {
      title: "View Changes",
      dataIndex: "buildID",
      key: "viewChanges",
      render: (text) => (
        <Button onClick={() => handleClick(text)}>Check</Button>
      ),
    },
  ];

  const columnsChangesPin = [
    {
      title: <Button onClick={openAllChangesModal}>All Changes</Button>,
      dataIndex: "buildID",
      key: "viewChanges",
      render: (text) => (
        <Button onClick={() => handleClick(text)}>Check</Button>
      ),
    },
  ];
  
  const pinColumn = [
    {
      title: "",
      key: "pin",
      render: (_, record) => (
        <Button onClick={() => pinRow(record)}>Pin</Button>
      ),
    },
  ];

  const unpinAllRows = () => {
    setPinnedRows([]); // Clear the pinned rows array
    setPinnedRowsByComponent({}); // Clear the pinned rows by component object
  };
  const combinedColumns = [
    ...columns,
    ...columnsEnvironmentsToggle,
    ...columnsChanges,
    ...pinColumn,
  ];
  const combinedColumnsUnPin = [
    ...columns,
    ...columnsEnvironmentsBase,
    ...columnsChangesPin,
  ];

  return (
    <div>
      <div
        style={{
          fontSize: 20,
          color: "white",
          marginLeft: 20,
          marginBottom: 10,
        }}
      >
        Builds & Deployment Search
      </div>
      <div
        style={{
          marginLeft: 20,
          display: "flex",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <div style={{ width: 250, marginRight: 20 }}>
          <Select
            placeholder="Select a Deployable Component"
            style={{ width: "100%" }}
            onChange={setBuildsState}
          >
            {deployableComponents.map((val) => (
              <Option value={val.componentId} key={val.componentId}>
                {val.componentName}
              </Option>
            ))}
          </Select>
        </div>
        {/* Input Field */}
        <Input
          placeholder="Search a build Id"
          style={{ width: 200, marginRight: 20 }}
          onChange={getData}
        />

        {/* Search Button */}
        <Button
          type="primary"
          style={{
            background: "#141414",
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={handleButtonCliick}
        >
          Search Builds
        </Button>
      </div>
      <div>
        <Table
          style={{ marginTop: 20, marginLeft: 20, fontSize: "8px" }}
          columns={combinedColumns}
          dataSource={filteredBuilds.map((row) => ({
            ...row,
            key: row.buildID,
          }))}
          pagination={{
            pageSize: 5,
            position: ["bottomLeft"],
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            showSizeChanger: false,
          }}
        />
        {pinnedRows.length > 0 && (
          <div>
            <div
              style={{
                fontSize: 20,
                color: "white",
                marginLeft: 20,
                marginBottom: 10,
              }}
            >
              Selected Builds
            </div>
            <Table
              style={{ marginTop: 20, marginLeft: 20, fontSize: "8px" }}
              dataSource={pinnedRows}
              columns={[
                ...combinedColumnsUnPin,
                {
                  title: (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button onClick={unpinAllRows}>Unpin All</Button>
                    </div>
                  ),
                  key: "unpin",
                  render: (_, record) => (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button onClick={() => unpinRow(record)}>Unpin</Button>
                    </div>
                  ),
                },
              ]}
              pagination={false}
            />
          </div>
        )}
      </div>

      <Modal
        title={
          <span className="gold-title">{`Changes for Build ID: ${selectedBuildID}`}</span>
        }
        visible={modalVisible}
        onCancel={handleModalCancel}
        footer={null}
      >
        <div style={modalContentStyle}>
          {error && <div style={{ color: "red" }}>Error: {error}</div>}
          {empty && <div style={{ color: "green" }}>Feedback: {empty}</div>}
          {changes.length > 0 &&
            changes.map((change) => (
              <div key={change.id}>
                Change by:{" "}
                <span className="light-blue-text">{change.username}</span>{" "}
                Comment:{" "}
                <span className="light-green-text">{change.git_comment} </span>
              </div>
            ))}
        </div>
      </Modal>
      <Modal
        title="All Changes"
        visible={showAllChangesModal}
        onCancel={closeAllChangesModal}
        footer={null}
      >
        <div style={modalContentStyle}>
          {fetchPinnedChanges.reduce((uniqueItems, item) => {
            // Check if the item's buildID is already in uniqueItems
            if (!uniqueItems.some((uniqueItem) => uniqueItem.buildID === item.buildID)) {
              uniqueItems.push(item); // Add the item to the list of unique items
            }
            return uniqueItems;
          }, []).map((uniqueItem) => (
            <div key={uniqueItem.buildID}>
              {/* Display Component Name */}
              <h2>{uniqueItem.componentName}</h2>

              {/* Display Build ID and hyperlink inline */}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: '10px' }}>Build ID:</span>
                <a href={uniqueItem.buildUrl} target="_blank" rel="noopener noreferrer">
                  <h3 style={{ marginBottom: '0' }}>{uniqueItem.buildID}</h3>
                </a>
              </div>
              {uniqueItem.changes.map((change, index) => (
                <div key={index}>
                  <p style={{ marginBottom: '0' }}>
                    Change by:{" "}
                    <span className="light-blue-text">{change.username}</span>{" "}
                    Comment:{" "}
                    <span className="light-green-text">{change.git_comment}</span>
                  </p>
                </div>
              ))}
              <hr style={{ margin: '15px 0' }} /> {/* Add a line separator after each set of changes */}
            </div>
          ))}
        </div>
      </Modal>





    </div>
  );
}

export default Builds;