import React, { useEffect, useState } from 'react'
import { Table, Image, Input } from 'antd'
import { API, graphqlOperation } from "aws-amplify";
import { Link } from 'react-router-dom';

import { listCustomers } from "../graphql/queries";
import { listCustomerEnvironments } from "../graphql/queries";

function Clients() {

  const appEnvironmentName = null; // Replace with your dynamic value
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    fetchCustomers()
  }, []);

  async function fetchCustomers() {
    const api_ret = await API.graphql(graphqlOperation(listCustomerEnvironments))
    const items = api_ret.data.listCustomerEnvironments.items;

    // Deduplicate Environments field
    const deduplicatedItems = items.map(item => ({
      ...item,
      Environments: [...new Set(item.Environments.split(',').map(env => env.trim()))].join(', ')
    }));
  
    setCustomers(deduplicatedItems);
  }


  const columns = [
    {
      title: 'Customer',
      dataIndex: 'customerName',
      key: 'customerName',
      align: 'center'
    },
    {
      title: 'Logo',
      dataIndex: 'iconLocation',
      key: 'iconLocation',
      align: 'center',
      render: (text) => <Image height={40} width={80} src={text} alt="" />
    },
    {
      title: 'AWS Regions',
      //dataIndex: ['awsRegion', 'regionName'],
      dataIndex: 'regionName',
      key: 'regionName',
      align: 'center',
      sorter: (a, b) => a.regionName.localeCompare(b.regionName)
    },
    {
      title: 'Total',
      //dataIndex: ['awsRegion', 'regionName'],
      dataIndex: 'Total',
      key: 'Total',
      align: 'center',
      sorter: (a, b) => a.Total.localeCompare(b.Total)
    },
    {
      title: 'Environments',
      dataIndex: 'Environments',
      key: 'Environments',
      align: 'center',
      render: (text) => {
        if (!text) return null; // Handle empty or null values
    
        // Split, trim, deduplicate, and sort environments
        const environmentList = [...new Set(text.split(',').map(env => env.trim()))].sort();
    
        // Render environments as links
        return environmentList.map((environment, index) => (
          <span key={`${environment}-${index}`}>
            <Link to={`/environments/${environment}`}>{environment}</Link>
            {index < environmentList.length - 1 && ', '}
          </span>
        ));
      },
      sorter: (a, b) => a.Environments.localeCompare(b.Environments),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        return (
          <Input
            autoFocus
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        );
      },
      onFilter: (value, record) => {
        return record.Environments?.toLowerCase().includes(value.toLowerCase());
      },
    }
  ];

  return (
    
    <div style={{"margin-left": 30}}>
      <div style={{"fontSize": 30, "color": "white"}}>UDP Clients</div>
      <Table style={{ marginTop: 10, marginRight: 50 }} pagination={{ pageSize: 6, position: ['bottomLeft'], showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`, }} dataSource={customers} columns={columns}/>
    </div>

  )
}

export default Clients