import React, { useState } from 'react';
import { Tree } from 'antd';
import { API, graphqlOperation } from "aws-amplify";
import {  getArtifactoryObjectList } from "../graphql/queries"

const initTreeData = [
    {
      title: 'code-promotion',
      key: 'code-promotion',
    },
    {
      title: 'code-promotion-snapshot',
      key: 'code-promotion-snapshot',
    }
  ];

async function fetchArtifactoryObjects(path) {
  try {
    const response = await API.graphql(graphqlOperation(getArtifactoryObjectList, { path }));
    const listedObjects = response.data.getArtifactoryObjectList;
    let newChildren = [];
    for(let item of listedObjects){
      newChildren.push({
        "title": item.name,
        "isLeaf": item.isFile,
        "key": item.path
      })
    };
    return newChildren;
  } catch (error) {
    console.error('Error fetching objects from artifactory:', error);
    return [];
  }
}
  
  // It's just a simple demo. You can use tree map to optimize update perf.
  const updateTreeData = (list, key, children) =>
    list.map((node) => {
      if (node.key === key) {
        return {
          ...node,
          children,
        };
      }
      if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children, key, children),
        };
      }
      return node;
    });

const ArtifactoryExplorer = () => {
    const rootStyle = {
      paddingRight: 10,
      paddingTop: 5,
      marginBottom: 10,
      height: "50%"
    };

    const [treeData, setTreeData] = useState(initTreeData);
    const onLoadData = ({ key, children }) =>
      new Promise((resolve) => {
        if (children) {
          resolve();
          return;
        }
        fetchArtifactoryObjects(key).then(listedObjects => {
          console.dir(listedObjects);
          setTreeData((origin) =>
            updateTreeData(origin, key, listedObjects),
          );
          resolve();
        });
      });
    return <Tree margin loadData={onLoadData} treeData={treeData} rootStyle={rootStyle}/>;
  };

export default ArtifactoryExplorer;