import React, { useEffect, useState}  from 'react'
import { Input} from "antd"
import { Table } from 'antd'
import {  getS3ObjectList } from "../graphql/queries"
import { API } from "aws-amplify";


function S3Explorer() {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      // Fetch data from your Amplify GraphQL API
      fetchData();
    }, []);
  
    const fetchData = async () => {
      try {
        const response = await API.graphql({
          query:getS3ObjectList/* Your GraphQL query to fetch all_object_keys */,
        });

        setData(response.data.getS3ObjectList);
        setLoading(false);
    } catch (error) {
        console.log('Error fetching data:', error);
        setLoading(false);
      }
    };

 
      const columns = [
        {
            title: 'S3 Object Key',
            dataIndex: 'key',
            key: 'key',
            sorter: (a, b) => a.key.localeCompare(b.key),
            render: (text) => <span style={{ color: 'yellow' }}>{text}</span>,
      filterDropdown:({setSelectedKeys, selectedKeys, confirm})=>{
        return <Input 
        autoFocus 
        placeholder="Type text here" 
        value={selectedKeys[0]}
        onChange={(e)=>{
          setSelectedKeys(e.target.value?[e.target.value]:[])
        }}
        onPressEnter={() => {
          confirm()
        }}
        onBlur={() => {
          confirm()
        }}
        ></Input>
      },
      onFilter: (value, record) => {
        return record.key.toLowerCase().includes(value.toLowerCase())
      },
        },

      ];

  return (

    <div>
     <Table loading={loading} dataSource={data} columns={columns} pagination={{ pageSize: 5 }}/>
    </div>

  )
}

export default S3Explorer