import { Space, Statistic, Card, Table, Tag, Select } from 'antd';
import ArrowCircleRightTwoToneIcon from '@mui/icons-material/ArrowCircleRightTwoTone';
import React, { useEffect, useState } from 'react'
import {listCicdPipelineInstances, getCicdTrailMap, describeAppsetsInPipeline, getReleaseAppset} from "../graphql/queries"
import { API, graphqlOperation } from "aws-amplify";

const { Option } = Select;


function TrailMapper() {

    const [cicdPipelineInstances, setCicdPipelineInstances] = useState([])
    const [selectedCicdPipelineInstance, setSelectedCicdPipelineInstance] = useState(null)
    const [selectedCicdAppsetId, setSelectedCicdAppsetId] = useState(null)
    const [cicdAppsets, setCicdAppsets] = useState([])
    const [cicdTrailMap, setCicdTrailMap] = useState([
        {"state": "Development", "status_category": "na"},
        {"state": "Integration", "status_category": "na"},
        {"state": "QA", "status_category": "na"},
        {"state": "Production", "status_category": "na"}
    ]);
    const [releaseAppset, setReleaseAppset] = useState([])

    const color_map = {pass: "#008000", failed: "#FF0000", in_progress: "#FFA500", ready: "dodgerblue", na: "grey"}

    useEffect(() => {
        listCicdPipelineInstancesFunc()
      }, [])

    async function listCicdPipelineInstancesFunc(){
        const api_ret = await API.graphql(graphqlOperation(listCicdPipelineInstances))
        setCicdPipelineInstances(api_ret.data.listCicdPipelineInstances)
    }

    async function setSelectedAppsetAndFetchCicdTrailMap(value){
        setSelectedCicdAppsetId(value)
        const api_ret = await API.graphql(graphqlOperation(getCicdTrailMap, {"appsetId": value, "pipelineInstanceName": selectedCicdPipelineInstance}))
        setCicdTrailMap(api_ret.data.getCicdTrailMap)
    }

    async function setSelectedCicdPipelineInstanceAndFetchAppsets(value){
        setSelectedCicdPipelineInstance(value)
        setSelectedCicdAppsetId(undefined)
        const api_ret = await API.graphql(graphqlOperation(describeAppsetsInPipeline, {"pipelineInstanceName": value, "appsetIds": [], "include_appsets_in_terminal_states": true, "exclude_redundant_appsets": true}))
        const api_ret2 = await API.graphql(graphqlOperation(getReleaseAppset, {"pipelineInstanceName": value}))
        setCicdAppsets(api_ret.data.describeAppsetsInPipeline)
        setReleaseAppset(api_ret2.data.getReleaseAppset.serverlessBuilds)
    }

    //const handleInputChange = (event) => {
    //    setCommitId(event.target.value);
    //};


    function formatBuildInfo(buildList) {
        return buildList.map(commit => {
            let appname = commit.serverless_app.appname;
            let commitId = commit.commit_id;
            return `Appname=${appname},CommitId=${commitId}`;
        }).join(' ');
    }

    function AppsetSelector() {
        return (
          <div>
            <h4 style={{ color: 'white' }}>Select Appset</h4>
            <Select placeholder="Appset" style={{ width: 400 }} onChange={setSelectedAppsetAndFetchCicdTrailMap} value={selectedCicdAppsetId}>
            {cicdAppsets.map((val) => {
                return (<Option value={val.appset.appsetId}>{formatBuildInfo(val.appset.serverlessBuilds)}</Option>)
            })}
            </Select>
          </div>
        );
     }
    function CicdPipelineInstanceSelector() {
        return (
          <div>
            <h4 style={{ color: 'white' }}>Select CI/CD Pipeline Instance</h4>
            <Select placeholder="Pipeline Instance Name" style={{ width: 400 }} onChange={setSelectedCicdPipelineInstanceAndFetchAppsets} value={selectedCicdPipelineInstance}>
            {cicdPipelineInstances.map((val) => {
                return (<Option value={val}>{val}</Option>)
            })}
            </Select>
          </div>
        );
    }


    function PipelineStageCard({ title, value, status }) {
      let opacity_val;

      if (value === undefined) {
        value = "TBA";
        status = "grey";
        opacity_val = 0.25;
      }
      else {
        opacity_val = 1;
      }

      return (
        <Card
          style={{
            backgroundColor: status,
            color: "white",
            opacity: opacity_val,
            width: 200,
            height: 120
          }}
        >
          <Space direction="horizontal">
            <Statistic
              title={
                <span style={{ fontSize: "17px", color: "white", fontWeight: "bold" }}>
                  {title}
                </span>
              }
              valueStyle={{ fontSize: "15px" }}
              value={value}
            />
          </Space>
        </Card>
      );
    }


    function CICDPipeline() {
        return (
            <Space direction="horizontal">
            {cicdTrailMap.map((entry, index) => (
                <React.Fragment key={index}>
                    <PipelineStageCard
                        title={entry.state}
                        value={entry.status}
                        status={color_map[entry.status_category]}
                    />
                    {index !== cicdTrailMap.length - 1 && (
                        <ArrowCircleRightTwoToneIcon fontSize='medium' style={{ color: "white" }} />
                    )}
                </React.Fragment>
            ))}
            </Space>
        );
    }

    function ReleaseAppset() {
        const dataSource = releaseAppset.map((val) => ({
            "appname": val.serverless_app.appname,
            "commit_id": val.commit_id
        }));

        const columns = [
            {
                title: 'AppName',
                dataIndex: 'appname',
                key: 'appname',
            },
            {
                title: 'CommitId',
                dataIndex: 'commit_id',
                key: 'commit_id',
            }
        ];

        return(<Table style={{ width: 475 }} dataSource={dataSource} columns={columns} pagination={{position: ["bottomLeft"]}} />);
    }



    function ColorLegend() {
        return (
          <div style={{ marginTop: '20px', textAlign: 'center' }}>
            <h6 style={{ color: 'white' }}>Legend</h6>
            <div>
              <Tag color={color_map.ready} style={{ marginRight: 8 }}>Ready</Tag>
              <Tag color={color_map.in_progress} style={{ marginRight: 8 }}>InProgress</Tag>
              <Tag color={color_map.pass} style={{ marginRight: 8 }}>Pass</Tag>
              <Tag color={color_map.failed} style={{ marginRight: 8 }}>Fail</Tag>
              <Tag color={color_map.na} style={{ marginRight: 8 }}>NA</Tag>
            </div>
          </div>
        );
    }


  return (
        <div style={{ marginLeft: '30px' }}>
            <div style={{
              color: "yellow",
              fontWeight: "bold",
              fontSize: 30,
              textAlign: "center",
              padding: 70,
              width: "70%",
              height: 50,
              position: "absolute",
              top: 0,
              left: "55%",
              transform: "translateX(-50%)",
            }}>Trail Mapper</div>
            <h1 style={{ color: 'white' }}>CICD Pipeline View</h1>
            <CicdPipelineInstanceSelector/>
            <Space direction="vertical">
                <div style={{ marginTop: '40px'}}>
                    <h2 style={{ color: 'white' }}>Released Serverless Builds</h2>
                    <ReleaseAppset />
                </div>
                <div style={{ marginTop: '40px'}}>
                    <h2 style={{ color: 'white' }}>Track Appset</h2>
                    <AppsetSelector />
                    <div style={{ marginTop: '20px', textAlign: 'center' }}>
                        <CICDPipeline />
                        <ColorLegend />
                    </div>
                </div>
            </Space>
        </div>
    );
}

export default TrailMapper