import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { AutoComplete, Select, Table, Card, Col, Row, Statistic, Input, Popover, Button, Modal, Tooltip } from 'antd';
import { API, graphqlOperation } from "aws-amplify";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import PeopleIcon from '@mui/icons-material/People';
import AppsIcon from '@mui/icons-material/Apps';
import { formatDateTime  } from './helper'; // Adjust the path based on the actual file location
import { formatDateTime2nd  } from './helper'; // Adjust the path based on the actual file location

import tc_logo from "../images/TeamCity_Icon.png"
import nx_logo from "../images/Nx_Icon.png"

import { listApp_Environments } from "../graphql/queries";
import { listDeploymentsWithBuildId } from "../graphql/queries";
import { getMetricCountDeployedComponentsWithEnvironmentId, getTeamCityBuildChanges } from "../graphql/queries";


import { listDeployments3 } from "../customGraphQL/queries";

const { Option } = Select;


function Environments() {
  const { appEnvironmentName } = useParams();

  const [appEnvironments, setAppEnvironments] = useState([])
  const [environmentDescription, setEnvironmentDescription] = useState({})
  const [deployments, setDeployments] = useState([])
  const [countDeployments, setCountDeploymentsInEnvironment] = useState([])

  const [visible, setVisible] = useState(false);
  const [selectedBuildID, setSelectedBuildID] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [changes, setChanges] = useState([]);
  const [error, setError] = useState(null);
  const [empty, setEmpty] = useState(null); 
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [isModalClosed, setIsModalClosed] = useState(false);
  const [allChangesModalVisible, setAllChangesModalVisible] = useState(false);
  const [showAllChangesButton, setShowAllChangesButton] = useState(false);
  const [fetchAllChanges, setFetchAllChanges] = useState([]);
  const [componentName, setComponentName] = useState('');
  const [buildUrl, setBuildUrl] = useState('');
  const [checkModalVisible, setCheckModalVisible] = useState(false);
  const [selectedCheckBuildData, setSelectedCheckBuildData] = useState(null);



  useEffect(() => {
    // This effect listens for changes in the 'deployments' state
    // Whenever 'deployments' changes, it extracts buildID, componentName, and buildUrl
    // and updates 'fetchAllChanges'
    const updateFetchAllChanges = async () => {
      const fetchedChanges = await Promise.all(
        deployments.map(async (deployment) => {
          const { buildID, componentName, buildUrl } = deployment;
          try {
            const response = await API.graphql(
              graphqlOperation(getTeamCityBuildChanges, { buildID })
            );
            const changesData = response.data.getTeamCityBuildChanges;
            return { buildID, componentName, buildUrl, changes: changesData };
          } catch (error) {
            console.error('Error fetching changes:', error);
            return { buildID, componentName, buildUrl, changes: [] }; 
          }
        })
      );
      // Update the 'fetchAllChanges' state with the fetched changes
      setFetchAllChanges(fetchedChanges);
    };
    updateFetchAllChanges();
  }, [deployments]);

  console.log("fetchAllChanges: ", fetchAllChanges)

  const handleCheckButtonClick = (buildID) => {
    const selectedBuildData = fetchAllChanges.find((change) => change.buildID === buildID);
    setSelectedCheckBuildData(selectedBuildData);
    setCheckModalVisible(true);
  };
  
  const handleAllChangesModalClose = () => {
    setAllChangesModalVisible(false);
  };

  const handleHover = (buildID) => {
    setSelectedBuildID(buildID);
    setVisible(true);
  };

  const handleAllChangesModalOpen = () => {
    console.log('fetchAllChanges:', fetchAllChanges); 
    setAllChangesModalVisible(true);
  };

  const handleClick = (buildID) => {

  };

  
  

useEffect(() => {
  if (selectedBuildID) {
    const fetchChanges = async () => {
      try {
        setChanges([]);
        setEmpty(null);
        const response = await API.graphql(
          graphqlOperation(getTeamCityBuildChanges, { buildID: selectedBuildID })
        );
        const changesData = response.data.getTeamCityBuildChanges;
        setChanges(changesData);
        setError(null);

        // Create an array of objects containing buildID, componentName, and buildUrl
        const changesArray = changesData.map((change) => ({
          buildID: selectedBuildID,
          componentName: change.componentName, 
          buildUrl: change.buildUrl, 
        }));
        // Update the fetchAllChanges state with the new data
        setFetchAllChanges(changesArray);
      } catch (error) {
        console.error('Error fetching changes:', error);
        setError(
          'Older or older SNAPSHOT build (no longer active on build system). Build details not available check with SCM team.'
        );
      }
    };

    fetchChanges();
  }
}, [selectedBuildID]);

  useEffect(() => {
    fetchAppEnvironments()
  }, [])

   useEffect(() => {
    setShowAllChangesButton(true);
  }, []);
   
  useEffect(() => {
    if (!modalVisible) {
      // Reset changes and error when the modal is closed
      setChanges([]);
      setError(null);
      setIsInitialRender(true);
    }
  }, [modalVisible]);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
    }
  }, [isInitialRender]);

  useEffect(() => {
    // Fetch appEnvironments and describe environment
    fetchAppEnvironments2(appEnvironmentName);
  
    // Clear changes when the environment changes
    setChanges([]); // Reset changes to empty
  }, [appEnvironmentName]);

  const handleModalCancel = () => {
    setModalVisible(false);
    setError(null);
    setIsModalClosed(true);
  };

  useEffect(() => {
    if (isModalClosed) {
      setIsModalClosed(false);
    }
  }, [isModalClosed]);

  async function fetchAppEnvironments2(value) {
    if (value) {
    try {
      const api_ret = await API.graphql(graphqlOperation(listApp_Environments));
      const temp = api_ret.data.listApp_Environments.items;
      setAppEnvironments(api_ret.data.listApp_Environments.items);
      
      // Now that the state has been updated, call describeEnvironment
      const selectedEnv = temp.find(x => x.appEnvironmentName === value);
      const appEnvironmentIds = selectedEnv.appEnvironmentID;

      setEnvironmentDescription(selectedEnv)
      const query_filter = { appEnvironmentID: { eq: appEnvironmentIds } }
      const api_ret1 = await API.graphql(graphqlOperation(listDeploymentsWithBuildId, {"filter": query_filter}))
      setDeployments(api_ret1.data.listDeploymentsWithBuildId.items)

      const api_ret2 = await API.graphql(graphqlOperation(getMetricCountDeployedComponentsWithEnvironmentId, {"appEnvironmentID":appEnvironmentIds}))
      setCountDeploymentsInEnvironment(api_ret2.data.getMetricCountDeployedComponentsWithEnvironmentId)
    } catch (error) {
      console.error("Error fetching appEnvironments2:", error);
    }
  }
  }
  
  async function fetchAppEnvironments() {
    const api_ret = await API.graphql(graphqlOperation(listApp_Environments))
    setAppEnvironments(api_ret.data.listApp_Environments.items)
  }

  useEffect(() => {
  }, [appEnvironments]);
  
  async function describeEnvironment(value) {
    if (!isNaN(value)) {
      const selectedEnv = appEnvironments.find(x => x.appEnvironmentID === value);
      setEnvironmentDescription(selectedEnv)
    } else {
      // Handle alphabetical string case here
    }
    const query_filter = { appEnvironmentID: { eq: value } }
    const api_ret = await API.graphql(graphqlOperation(listDeploymentsWithBuildId, {"filter": query_filter}))
    setDeployments(api_ret.data.listDeploymentsWithBuildId.items)

    const api_ret2 = await API.graphql(graphqlOperation(getMetricCountDeployedComponentsWithEnvironmentId, {"appEnvironmentID":value}))
    setCountDeploymentsInEnvironment(api_ret2.data.getMetricCountDeployedComponentsWithEnvironmentId)
  }

  const deployment_columns = [

  {
    title: 'buildID',
    dataIndex: 'buildID',
    key: 'buildID',
    render: (text, record) => (
      <span style={{ fontSize: '12px' }}>
        <Tooltip title={`Click to open link for build ${text}`}>
          <a href={record.buildUrl} target="_blank" rel="noopener noreferrer">
            {text}
          </a>
        </Tooltip>
      </span>
    ),
    sorter: (a, b) => a.buildID.localeCompare(b.buildID),
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
      return (
        <Input
          autoFocus
          placeholder="Type text here"
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => {
            confirm();
          }}
          onBlur={() => {
            confirm();
          }}
        ></Input>
      );
    },
    onFilter: (value, record) => {
      return record.buildID.toLowerCase().includes(value.toLowerCase());
    },
  },
  {
    title: 'componentName',
    dataIndex: 'componentName', 
    key: 'componentName', 
    sorter: (a, b) => a.componentName.localeCompare(b.componentName),
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
      return (
        <Input
          autoFocus
          placeholder="Type text here"
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => {
            confirm();
          }}
          onBlur={() => {
            confirm();
          }}
        ></Input>
      );
    },
    onFilter: (value, record) => {
      return record.componentName.toLowerCase().includes(value.toLowerCase());
    },
  },
  {
    title: 'Version',
    dataIndex: 'version',
    key: 'version',
    sorter: (a, b) => a.version.localeCompare(b.version),
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
      return (
        <Input
          autoFocus
          placeholder="Type text here"
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => {
            confirm();
          }}
          onBlur={() => {
            confirm();
          }}
        ></Input>
      );
    },
    onFilter: (value, record) => {
      return record.version.toLowerCase().includes(value.toLowerCase());
    },
  },
    {
      title: 'Build Date Time',
      dataIndex: 'buildTimestamp',
      key: 'buildTimestamp',
      render: (text, record) => (
        <span>{formatDateTime2nd(record.buildTimestamp)}</span>
      ),
     // sorter: (a, b) => a.buildTimestamp.localeCompare(b.version)
     // should add later when date timestamp is in sortable format.
    },
    {
      title: 'Deployment Date Time',
      dataIndex: 'dateTimeStamp',
      key: 'dateTimeStamp',
    },
    {
      title: (
      showAllChangesButton && (
        <Tooltip title={`Click to aggregate all changes`}>
          <Button onClick={() => setAllChangesModalVisible(true)}>All Changes</Button>
        </Tooltip>
      )
        ),
      dataIndex: 'buildID',
      key: 'viewChanges',
      width: 150,
      render: (text) => (
        <Button onClick={() => handleCheckButtonClick(text)}>Check</Button>
      ),
    }
  ];

  const cardStyle = {
    width: '175px', // Set the desired width
    height: '115px', // Set the desired height
  };
  const statisticStyle = {
    fontSize: '10px', // Set the font size
    color: 'white', // Set the text color
  };

  const modalContentStyle = {
    maxHeight: '400px', // Set the maximum height for the pop up modal for changes
    overflowY: 'auto', // Enable vertical scrollbar if content exceeds maxHeight
  };


  return (
    <div style={{"margin-left": 30, "margin-top": 30}}>
      <div style={{"fontSize": 18, "color": "white"}}>Discover Environments</div>

      
      <Select placeholder="Select an Environment" style={{ width: 250, "margin-top": 5 }} onChange={describeEnvironment} defaultValue={appEnvironmentName}>
      {appEnvironments.map((val) => {
        return (<Option value={val.appEnvironmentID}>{val.appEnvironmentName}</Option>)
      })}
      </Select>

      { Object.keys(environmentDescription).length > 0 &&
      <div style={{"margin-top": 15, "display": "flex", "flex-direction": "column"}}>
        <div style={{"verticalAlign": "top"}}>
          <Row style={{"margin-top": 5}} gutter={5}>

            <Col span={8}>
              <Card bordered={true} style={cardStyle}>
                <Statistic
                  title="Customer"
                  value={environmentDescription.customerName}
                  valueStyle={{fontSize: 14}}
                  prefix={<PeopleIcon/>}
                  style={statisticStyle}
                />
              </Card>
            </Col>

            <Col span={8}>
              <Card bordered={false} style={cardStyle}>
                <Statistic
                  title="Environment Status"
                  value={environmentDescription.environmentStatus}
                  valueStyle={environmentDescription.environmentStatus === 'Active' ? ({ color: '#00b96b', fontSize: '14px' }) : ({color: 'red', fontSize: '14px'}) }
                  prefix={environmentDescription.environmentStatus === 'Active' ? (<ArrowUpwardIcon/>) : (<ArrowDownwardIcon/>)}
                  style={statisticStyle}
                />
              </Card>
            </Col>


            <Col span={8}>
              <Card bordered={false} style={cardStyle}>
              <p style={{ color: "#7E7E7E", fontSize: "14px" }}>Self Service Link</p>
                <div style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "50%" }}>
                  <a href={environmentDescription.ssProjectURL} rel="noreferrer" target="_blank">
                    <img height={35} width={35} src={tc_logo} alt=""/>
                  </a>
                </div>
              </Card>
            </Col>
            </Row>

            <Row style={{"margin-top": 10, "margin-bottom": 10}} gutter={5}>
            <Col span={8}>
              <Card bordered={false} style={cardStyle}>
              <p style={{ color: "#7E7E7E", fontSize: "14px" }}>Environment Link</p>
                <div style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "50%" }}>
                  <a href={environmentDescription.appEnvironmentURL} rel="noreferrer" target="_blank">
                    <img height={30} width={30} src={nx_logo} alt=""/>
                  </a>
                </div>
              </Card>
            </Col>

            <Col span={8}>
              <Card bordered={true} style={cardStyle}>
                <Statistic
                  title="Total Deployments"
                  value={countDeployments}
                  valueStyle={{fontSize: 14}}
                  prefix={<AppsIcon/>}
                  style={statisticStyle}
                />
              </Card>
            </Col>

            <Col span={8}>
              <Card bordered={true} style={cardStyle}>
                <Statistic
                  title="Usecase"
                  value={environmentDescription.usecase}
                  valueStyle={{fontSize: 14}}
                  style={statisticStyle}
                />
              </Card>
            </Col>

          </Row>
        
        </div>
        <Row>
        <div style={{"verticalAlign": "top", "margin-top": 5}}>
          <div style={{"fontSize": 18, "color": "white"}}>Deployed Components</div>
          <Table pagination={{ pageSize: 3, position: ['bottomLeft'] }} style={{"margin-top": 10}} dataSource={deployments} columns={deployment_columns}/>


          <Modal
            title="All Changes"
            visible={allChangesModalVisible}
            onCancel={handleAllChangesModalClose}
            footer={null}
            width={750}
          >
            {fetchAllChanges.map((change, index) => (
              <div key={change.buildID}>
                {index > 0 && <hr />} {/* Add a horizontal line above except for the first item */}
                <p>
                  <h2>{change.componentName}</h2>
                  Build ID: <a href={change.buildUrl} target="_blank" rel="noopener noreferrer">
                    {change.buildID}
                  </a>
                </p>
                <p>Changes:</p>
                <ul>
                  {change.changes.map((changeItem, changeIndex) => (
                    <li key={changeIndex}>
                      Change by: <span style={{ color: 'lightblue' }}>{changeItem.username}</span> Comment: {' '}
                      <a href={changeItem.webUrl} target="_blank" rel="noopener noreferrer">
                        {changeItem.git_comment}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </Modal>

          <Modal
            title=""
            visible={checkModalVisible}
            onCancel={() => setCheckModalVisible(false)}
            footer={null}
            width={750} // Adjust the width as needed
          >
            {selectedCheckBuildData && (
              <div>
                <p>
                  <h2>{selectedCheckBuildData.componentName}</h2>
                  Build ID:{" "}
                  <a href={selectedCheckBuildData.buildUrl} target="_blank" rel="noopener noreferrer">
                    {selectedCheckBuildData.buildID}
                  </a>
                </p>
                <p>Changes:</p>
                <ul>
                  {selectedCheckBuildData.changes.map((changeItem, changeIndex) => (
                    <li key={changeIndex}>
                      Change by: <span style={{ color: "lightblue" }}>{changeItem.username}</span> Comment:{" "}
                      <a href={changeItem.webUrl} target="_blank" rel="noopener noreferrer">
                        {changeItem.git_comment}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </Modal>
        </div>
        </Row>
      </div>
      }
    </div>
  )
}

export default Environments