import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import App from "./App"

export default function LandingPage() {
  return (
    <Authenticator
      hideSignUp={true}
    >
      <div>
        <App/>
      </div>
    </Authenticator>
  );
}