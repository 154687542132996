import React from "react";
import {Route, Routes} from "react-router-dom";
import Builds from "./Pages/Builds";
import Environments from "./Pages/Environments";
import Home from "./Pages/Home";
import Clients from "./Pages/Clients";
import Metrics from "./Pages/Metrics";
import DPverse from "./Pages/DPverse";
import TrailMapper from "./Pages/TrailMapper";
import Explorer from "./Pages/DZExplorer";

function DashboardRoutes() {
    return (

        <Routes>
            <Route exact path="/" element={<Home/>}/>
            <Route exact path="/home" element={<Home/>}/>
            <Route exact path="/builds" element={<Builds/>}/>
            <Route exact path="/environments" element={<Environments/>}/>
            <Route exact path="/clients" element={<Clients/>}/>
            <Route exact path="/metrics" element={<Metrics/>}/>
            <Route exact path="/dpverse" element={<DPverse/>}/>
            <Route exact path="/trailmapper" element={<TrailMapper/>}/>
            <Route exact path="/dzexplorer" element={<Explorer/>}/>
            <Route path="/environments/:appEnvironmentName" element={<Environments/>} />

        </Routes>

    )
}

export default DashboardRoutes;