import { Menu } from "antd";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import Build from "@mui/icons-material/Build";
import Public from "@mui/icons-material/Public";
import BusinessIcon from "@mui/icons-material/Business";
import TimelineIcon from "@mui/icons-material/Timeline";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import { Auth } from "aws-amplify";

const Sidebar = () => {
  const location = useLocation();
  const currentPath = location.pathname; // Get the current pathname
  const navigate = useNavigate();

  async function signOut() {
    try {
      navigate("/");
      await Auth.signOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }
  const isEnvironmentsActive = currentPath.startsWith('/environments');
  return (
    <Menu
      onClick={({ key }) => {
        key === "signout" ? signOut() : navigate(key);
      }}
      selectedKeys={[currentPath, isEnvironmentsActive ? '/environments' : null]} // Highlight the active item based on the current path
      style={{ width: "22.5%", height: "110vh", fontSize: 30 }}
      items={[
        { label: "Home", key: "/home", icon: <HomeIcon style={{ fontSize: 30 }} />, active: currentPath === "/home" },
        { label: "Builds", key: "/builds", icon: <Build style={{ fontSize: 30 }} />, active: currentPath === "/builds" },
        { label: "Environments", key: "/environments", icon: <Public style={{ fontSize: 30 }} />, active: isEnvironmentsActive },
        { label: "Clients", key: "/clients", icon: <BusinessIcon style={{ fontSize: 30 }} />, active: currentPath === "/clients" },
        { label: "Metrics", key: "/metrics", icon: <TimelineIcon style={{ fontSize: 30 }} />, active: currentPath === "/metrics" },
        { label: "DPverse", key: "/dpverse", icon: <AllInclusiveIcon style={{ fontSize: 30 }} />, active: currentPath === "/dpverse" },
        { label: "TrailMapper", key: "/trailmapper", icon: <TroubleshootIcon style={{ fontSize: 30 }} />, active: currentPath === "/trailmapper" },
        { label: "DZExplorer", key: "/dzexplorer", icon: <WarehouseIcon style={{ fontSize: 30 }} />, active: currentPath === "/dzexplorer" },
        { label: "SignOut", key: "signout", icon: <ExitToAppIcon style={{ fontSize: 30 }} />, active: currentPath === "signout" },
      ]}
    />
  );
};

export default Sidebar;

