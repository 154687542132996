import React, { useEffect, useState } from 'react'
import { Select, Table, Card, Col, Row, Statistic, Input, Tabs } from 'antd';
import { API, graphqlOperation } from "aws-amplify";
import PeopleIcon from '@mui/icons-material/People';
import { getBranches, getSsExecutionList, listAllDeploymentExceptions } from '../graphql/queries';
import { formatDateTime2nd } from './helper'; // Adjust the path based on the actual file location
import { Link } from "react-router-dom";

const repositoryName = 'udp-pipeline-repo';

const { TabPane } = Tabs;
const BranchesComponent = () => {
    const [branches, setBranches] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState('');
    const [countSsExecutionList, setCountSsExecutionList] = useState([])
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('selfService'); // Default active tab
    const [depExceptions, setDepExceptions] = useState([])
  
    useEffect(() => {
      fetchBranches()
      fetchSsExecutionList()
      fetchDeploymentExceptions(); 
    }, []);
    
    const columns = [
      {
        title: 'Environment',
        dataIndex: 'environmentName',
        key: 'environmentName',
        render: (text) => <span style={{ fontSize: '10px' }}>{text}</span>,
        sorter: (a,b) => a.environmentName.localeCompare(b.environmentName),
        filterDropdown:({setSelectedKeys, selectedKeys, confirm})=>{
          return <Input 
          autoFocus 
          placeholder="Type text here" 
          value={selectedKeys[0]}
          onChange={(e)=>{
            setSelectedKeys(e.target.value?[e.target.value]:[])
          }}
          onPressEnter={() => {
            confirm()
          }}
          onBlur={() => {
            confirm()
          }}
          ></Input>

        },
        onFilter: (value, record) => {
          return record.environmentName.toLowerCase().includes(value.toLowerCase())
        },
      },
      {
        title: 'Status',
        dataIndex: 'statusName',
        key: 'statusName',
        render: (text) => <span style={{ fontSize: '10px' }}>{text}</span>,
        filterDropdown:({setSelectedKeys, selectedKeys, confirm})=>{
          return <Input 
          autoFocus 
          placeholder="Type text here" 
          value={selectedKeys[0]}
          onChange={(e)=>{
            setSelectedKeys(e.target.value?[e.target.value]:[])
          }}
          onPressEnter={() => {
            confirm()
          }}
          onBlur={() => {
            confirm()
          }}
          ></Input>

        },
        onFilter: (value, record) => {
          return record.statusName.toLowerCase().includes(value.toLowerCase())
        },
      },
      {
        title: 'Platform',
        dataIndex: 'platformName',
        key: 'platformName',
        render: (text) => <span style={{ fontSize: '10px' }}>{text}</span>
      },
      {
        title: 'Task',
        dataIndex: 'taskName',
        key: 'taskName',
        render: (text) => <span style={{ fontSize: '10px' }}>{text}</span>,
        filterDropdown:({setSelectedKeys, selectedKeys, confirm})=>{
          return <Input 
          autoFocus 
          placeholder="Type text here" 
          value={selectedKeys[0]}
          onChange={(e)=>{
            setSelectedKeys(e.target.value?[e.target.value]:[])
          }}
          onPressEnter={() => {
            confirm()
          }}
          onBlur={() => {
            confirm()
          }}
          ></Input>

        },
        onFilter: (value, record) => {
          return record.taskName.toLowerCase().includes(value.toLowerCase())
        },
      },
      {
        title: 'Parameters',
        dataIndex: 'parameters',
        key: 'parameters',
        render: (text) => <span style={{ fontSize: '8px' }}>{text}</span>,
        filterDropdown:({setSelectedKeys, selectedKeys, confirm})=>{
          return <Input 
          autoFocus 
          placeholder="Type text here" 
          value={selectedKeys[0]}
          onChange={(e)=>{
            setSelectedKeys(e.target.value?[e.target.value]:[])
          }}
          onPressEnter={() => {
            confirm()
          }}
          onBlur={() => {
            confirm()
          }}
          ></Input>

        },
        onFilter: (value, record) => {
          return record.parameters.toLowerCase().includes(value.toLowerCase())
        },
      },
      {
        title: 'Submitted Time',
        dataIndex: 'dateTimeStamp',
        key: 'dateTimeStamp',
        //render: (text) => <span style={{ fontSize: '10px' }}>{text}</span>,
       render: (text, record) => (
       <span style={{ fontSize: '10px' }}>{formatDateTime2nd(record.dateTimeStamp)}</span>
       ),
        sorter: (a,b) => a.dateTimeStamp.localeCompare(b.dateTimeStamp)
      }
    ];

const fetchBranches = async () => {
    try {
        const response = await API.graphql({
        query: getBranches,
        variables: { repositoryName },
        });
const branchesData = response.data.getBranches;
    setBranches(branchesData);
      } catch (error) {
        console.error('Error fetching branches:', error);
        
      }
    };

    async function fetchSsExecutionList() {
      const api_ret = await API.graphql(graphqlOperation(getSsExecutionList))
      setCountSsExecutionList(api_ret.data.getSsExecutionList.items)
      setLoading(false);
    }

    async function fetchDeploymentExceptions() {
      try {
        const api_ret = await API.graphql(graphqlOperation(listAllDeploymentExceptions));
        const deploymentExceptions = api_ret.data.listAllDeploymentExceptions.items;
        console.log("Deployment Exceptions:", deploymentExceptions);
        setDepExceptions(deploymentExceptions);
      } catch (error) {
        console.error('Error fetching deployment exceptions:', error);
      }
    }

    const handleBranchChange = (event) => {
        setSelectedBranch(event.target.value);
      };

      const paginationConfig = {
        pageSize: 4, // Set the number of rows to display per page
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`, 
        hideOnSinglePage: true, // Hide pagination when there is only one page of data
      };
      

      const tableStyle = {
        marginTop: 10,
        marginRight: 10,
        width: '80%', // Adjust the width of the table as needed
      };
    
      const cellStyle = {
        padding: '4px 8px', // Adjust the cell padding as needed
      };

      const columnsExceptions = [
        {
          title: 'Exception ID',
          dataIndex: 'deploymentExceptionID',
          key: 'deploymentExceptionID',
        },
        {
          title: 'Build ID',
          dataIndex: 'buildID',
          key: 'buildID',
        },
        {
          title: 'Environment',
          dataIndex: 'appEnvironmentName',
          key: 'appEnvironmentName',
          render: (text, record) => (
            <Link to={`/environments/${encodeURIComponent(record.appEnvironmentName)}`}>
              {text}
            </Link>
          ),
        },
        {
          title: 'Component',
          dataIndex: 'componentName',
          key: 'componentName',
        },
        {
          title: 'Execution ID',
          dataIndex: 'executionId',
          key: 'executionId',
        },
        {
          title: 'Task Name',
          dataIndex: 'taskName',
          key: 'executionId',
        },
        {
          title: 'Notes',
          dataIndex: 'notes',
          key: 'notes',
        },
        {
          title: 'dateTimeStamp',
          dataIndex: 'dateTimeStamp',
          key: 'dateTimeStamp',
          render: (text, record) => (
            <span style={{ fontSize: '12px' }}>{formatDateTime2nd(record.dateTimeStamp)}</span>
          ),
        },
      ];

      return (
        <div style={{ "margin-left": 30 }}>
          <Tabs activeKey={activeTab} onChange={setActiveTab}>
            <TabPane tab="Self Service Calls" key="selfService">
              {/* Content for the "Self Service Calls" tab */}
              <h3 style={{ "color": 'white', "margin-top": '35px' }}></h3>
              <Table style={tableStyle} loading={loading} size="small" 
                dataSource={countSsExecutionList} 
                columns={columns} 
                pagination={paginationConfig} 
              />
            </TabPane>
            <TabPane tab="Deployment Exceptions" key="exceptions">
              <Table
                dataSource={depExceptions}
                columns={columnsExceptions}
                rowKey="deploymentExceptionID"
                pagination={paginationConfig} // Adjust the page size as needed
              />
            </TabPane>
          </Tabs>
        </div>
      );
    };



export default BranchesComponent;