import "antd/dist/reset.css";
import "./App.css"

import Sidebar from "./Components/Sidebar"
import DashboardRoutes from "./Routes";
import { ConfigProvider, theme } from "antd";

function Header() {
  return (
    <div style={{
      height:100,  
      color: "white", 
      display: "flex", 
      fontWeight: "bold",
      fontSize:40,
      padding: "20px"
    }}><p>DP TRAKKER <em style={{fontSize: 35, padding: "10px"}}>dashboard</em></p>
    </div>
  )
}

function Footer() {
  return (
    <div style = {{ fontSize: 12, margin: 10, "margin-left": 15, color: "white", position: "fixed",bottom: "2px"}}>
      <p>© 2023 <a href="http://www.numerix.com" rel="noreferrer" target="_blank">Numerix LLC</a> - All Rights Reserved. <a href="https://nxoneview.atlassian.net/wiki/spaces/NXDEV/pages/2828435779/Unified+Pipeline+Self+Service+Guide" rel="noreferrer" target="_blank">Help</a> </p>
    </div>
  )
}

const App = () => {
  return (
    <ConfigProvider theme={{
      token: {
        colorPrimary: '#00b96b',
      },
      algorithm: theme.darkAlgorithm
    }}>
      <div className="App">
        <Header/>
        <div style={{display: "flex", flexDirection: "row"}}>
          <Sidebar/>
          <DashboardRoutes/>
        </div>
        <Footer/>
      </div>
    </ConfigProvider>
  );
};

export default App;
